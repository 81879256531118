import PAGE_TYPE from "../const/pageType";
// import EVENT_TYPE from "@/common-components-src/const/eventType";
import EVENT_TYPE from "@/common-components-src/const/eventType";
import IMAGEAPI_TYPE from "@/common-components-src/const/imageApiType";
import JDIHeaderNew from "./header/index";
import JDIFooterNew from "./footer/index2";
import JDILogin from "./newlogin/index.newlogin";
import JDICurrencySymbol from "./currencySymbol/index";
import JDIPrice from "./price/index";
import JDIProductCard from "@/common-components-src/js/productCard/index";
import JDIProductCardV2 from "@/common-components-src/js/productCard_V2/index";
import JDIFloatingToolbar from "./floatingToolbar/index";
import AccountAddress from "@/common-components-src/js/address/accountAddress/index";
import callAfterLogin from "./utils/callAfterLogin";
import { formatProductLink } from "./utils/formatProductLink";
import { imageUtil } from "./utils/imageUtil";
import eventEmitter from "./utils/eventEmitterUtil";
import { getAddressStore } from "@/common-components-src/js/address/AddressStore";
import { curPostCodeOrAddress } from "@/common-components-src/js/utils/addressRequestUtils";
import { ErrorTryAgain } from "./error";
import newColorAxiosInstance from "@/common-components-src/api/new-color-axiosInstance";
import UKDialog from "@/common-components-src/components/UKDialog/index";
import Recommend from "./recommend";
import ProductList from "./productList";
import UKCopy from "@/common-components-src/components/UKCopy";
import UKTruncatedTooltip from "@/common-components-src/components/UKTruncatedTooltip";
import UKToast from "@/common-components-src/components/UKToast/index";
import UKCounter from "@/common-components-src/components/UKCounter";
import UKImg from "@/common-components-src/components/UKImg";
import UKLoadingIcon from "@/common-components-src/components/UKLoadingIcon";
import UKMaskLoading from "@/common-components-src/components/UKMaskLoading/index";
import UKPageError from "@/common-components-src/components/UKPageError";
import UKPageLoading from "@/common-components-src/components/UKPageLoading";
import UKPageNothing from "@/common-components-src/components/UKPageNothing";
import UKUpload from "@/common-components-src/components/UKUpload";
import { imageUploadApi } from "@/common-components-src/api/image/index.api";
import GiftModal from "./gift";
import { getGift } from "./utils/giftUtils";
import sgmManager from "./utils/sgmUtils";
import { parseCookieString } from "@/utils/cookieUtils";
import Countdown from "./countdown/index";
import UKProductSelect from "@/common-components-src/components/UKProductSelect";
import ReveiwDetailList from "@/common-components-src/js/reviews/reviewDetailList";
import IntlDialog from "@/common-components-src/js/reviews/dialog";

export {
  PAGE_TYPE,
  EVENT_TYPE,
  IMAGEAPI_TYPE,
  JDIHeaderNew,
  JDIFooterNew,
  JDILogin,
  JDICurrencySymbol,
  JDIPrice,
  callAfterLogin,
  JDIProductCard,
  JDIProductCardV2,
  JDIFloatingToolbar,
  formatProductLink,
  imageUtil,
  eventEmitter,
  AccountAddress,
  getAddressStore,
  curPostCodeOrAddress,
  ErrorTryAgain,
  newColorAxiosInstance,
  UKDialog,
  Recommend,
  UKCopy,
  UKTruncatedTooltip,
  UKToast,
  UKCounter,
  UKImg,
  UKLoadingIcon,
  UKMaskLoading,
  UKPageError,
  UKPageLoading,
  UKPageNothing,
  imageUploadApi,
  GiftModal,
  UKUpload,
  getGift,
  sgmManager,
  ProductList,
  Countdown,
  parseCookieString,
  UKProductSelect,
  ReveiwDetailList,
  IntlDialog,
};

declare module "@/common-components-src/js/main" {
  export {
    JDIPrice,
    JDICurrencySymbol,
    callAfterLogin,
    formatProductLink,
    JDIProductCard,
    JDIFloatingToolbar,
    imageUtil,
    eventEmitter,
    AccountAddress,
    getAddressStore,
    curPostCodeOrAddress,
    ErrorTryAgain,
    newColorAxiosInstance,
    Recommend,
    JDIFooterNew,
    UKDialog,
    UKCopy,
    UKTruncatedTooltip,
    UKToast,
    UKCounter,
    UKImg,
    UKLoadingIcon,
    UKMaskLoading,
    UKPageError,
    UKPageLoading,
    UKPageNothing,
    EVENT_TYPE,
    IMAGEAPI_TYPE,
    imageUploadApi,
    GiftModal,
    UKUpload,
    JDIProductCardV2,
    getGift,
    sgmManager,
    ProductList,
    Countdown,
    parseCookieString,
    UKProductSelect,
    ReveiwDetailList,
    IntlDialog,
  };
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import styles from "./reviewDetail.module.scss";
import cn from "clsx";
const limitTextLength = 500;
interface Props {
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  numStyle?: any;
}
export default function CustomTextarea({
  numStyle,
  value: outerValue,
  placeholder,
  onChange,
}: Props) {
  const [value, setValue] = useState<string>(outerValue || "");
  const onTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const contentStr = event.target.value?.substring(0, limitTextLength) || "";
    setValue(contentStr);
    onChange?.(contentStr);
  };
  useEffect(() => {
    setValue(outerValue || "");
  }, [outerValue]);
  return (
    <div className={styles.customTextareaWrapper}>
      <textarea
        value={value}
        onChange={(event) => onTextareaChange(event)}
        placeholder={placeholder}
      ></textarea>
      <span className={cn(styles.num, numStyle)}>
        {value.length}/{limitTextLength}
      </span>
    </div>
  );
}

//独立图片上传
import IMAGEAPI_TYPE from "@/common-components-src/const/imageApiType";

// 正式环境图片上传域名配置
const PRODUCTION_APICONFIG = {
  [IMAGEAPI_TYPE.PersonalInfo]: "https://pic.joybuy.com/30/ee9bb0ac5466436dafac2445572c1d1e",
  [IMAGEAPI_TYPE.Reviews]: "https://pic.joybuy.com/30/9ad51062a80047e38782da4234a88eaa",
  [IMAGEAPI_TYPE.FeedBack]: "https://pic.joybuy.com/30/ddd2b2becd00430ea1ef58999d8bf15c",
  [IMAGEAPI_TYPE.AfterSale]: "https://pic.joybuy.com/30/2597085c4e7e4dba88d004fa9234793c",
}

// 测试环境图片上传域名配置
const DEVELOPMENT_APICONFIG = {
  [IMAGEAPI_TYPE.PersonalInfo]: "//pic.joybuy-test.com/30/af6dc2ddc49e46feabf0a00de353004f",
  [IMAGEAPI_TYPE.Reviews]: "//pic.joybuy-test.com/30/a5d59408dd104777a769b858e979d16b",
  [IMAGEAPI_TYPE.FeedBack]: "//pic.joybuy-test.com/30/112a799aa0ff4372a4b570966a896980",
  [IMAGEAPI_TYPE.AfterSale]: "//pic.joybuy-test.com/30/4077376e156641d6a0c9f5fc06477148",
}

const API_URLS = process.env.NEXT_PUBLIC_APP_ENV === "production" ? PRODUCTION_APICONFIG : DEVELOPMENT_APICONFIG;

export const imageUploadApi = async (file: any, type: IMAGEAPI_TYPE = IMAGEAPI_TYPE.PersonalInfo) => {
  const formdata = new FormData();
  formdata.append("file", file);
  formdata.append("common", '{"client":"pc","forceLoginType":30}');
  return fetch(API_URLS[type], {
    method: "POST",
    body: formdata,
    credentials: "include",
    redirect: "follow",
  })
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error: any) => {
      console.log(error);
    });
};

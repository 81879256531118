import to from "await-to-js";
import { Response } from "../api.interface";
import newColorAxiosInstance from "../new-color-axiosInstance";
import { MessageListResponse, DeleteResultRes, MessagePreviewResponse, MessageEnterace } from "./message.interface";

// doc: https://joyspace.jd.com/pages/OKN92AcEPEEiX1TYiRMc

// 获取首页消息
export const getMsgPreviewApi = async (): Promise<Response<MessagePreviewResponse>> => {
  return newColorAxiosInstance
    .post<Response<MessagePreviewResponse>>(`user_message_getMsgList`, undefined, {
      params: {
        appName: "C",
        languageCode: "en_US",
      },
    })
    .then((response: any) => response.data);
};

// 获取二级页面消息列表
export const getMsgListApi = async ({
  currentPage,
  pageSize,
  accountType,
}: {
  currentPage: number;
  pageSize: number;
  accountType: number;
}): Promise<Response<MessageListResponse>> => {
  return newColorAxiosInstance
    .post<Response<MessageListResponse>>(`user_message_getList`, undefined, {
      params: {
        appName: "C",
        languageCode: "en_US",
        accountType,
        currentPage,
        pageSize,
      },
    })
    .then((response: any) => response.data);
};

// 消息删除
export const handleBatchDeleteApi = async ({ msgId }: { msgId: string }): Promise<DeleteResultRes | null> => {
  const res = await newColorAxiosInstance.post<Response<DeleteResultRes>>(`user_message_delete`, undefined, {
    params: {
      appName: "C",
      languageCode: "en_US",
      msgId: [msgId],
    },
  });
  return {
    data: res?.data?.code === "200",
  };
};

// 按类型消息已读
export const updateReadedStatusApi = async (accountType: number): Promise<DeleteResultRes | null> => {
  const res = await newColorAxiosInstance.post<Response<DeleteResultRes>>(`user_message_markAsRead`, undefined, {
    params: {
      appName: "C",
      languageCode: "en_US",
      accountType,
    },
  });
  console.log("updateReadedStatusApi", res?.data);
  return {
    data: res?.data?.code === "200",
  };
};

// 小扫把一键清除
export const oneKeyReadApi = async (): Promise<DeleteResultRes | null> => {
  const res = await newColorAxiosInstance.post<Response<DeleteResultRes>>(`user_message_markAllAsRead`, undefined, {
    params: {
      appName: "C",
      languageCode: "en_US",
    },
  });
  console.log("oneKeyReadApi", res?.data);
  return {
    data: res?.data?.code === "200",
  };
};

// 获取用户未读消息角标信息
export const fetchGetMsgEntrance = (): Promise<Response<MessageEnterace>> => {
  return newColorAxiosInstance
    .post<Response<MessageEnterace>>("user_message_getMsgUnReadCount", undefined, {
      params: { appName: "C", languageCode: "en_GB" },
    })
    .then((response: any) => response.data)
    .catch((err: any) => err);
};

// 查询详情详情
export const fetchUserReviewDetail = async ({
  commentId,
  parentReplyId,
  skuId,
}: {
  commentId: string;
  parentReplyId: string | number;
  skuId: string;
}): Promise<any | null> => {
  const res = await newColorAxiosInstance.get<Response<any>>(`content_review_get`, {
    params: {
      commentId,
      parentReplyId,
      skuId,
      pageSize: 30,
    },
  });
  if (res?.data?.success) {
    return res?.data;
  }
  return res?.data;
};

// 查询订单详情
export const fetchOrderDetail = (requestData: { orderId: string }): Promise<any> => {
  return newColorAxiosInstance
    .get<any>("trade_order_get", {
      params: { ...requestData },
    })
    .then((response: any) => response.data.data);
};

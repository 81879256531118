/* eslint-disable @next/next/no-img-element */
import React from "react";
import Button from "@mui/material/Button";
import styles from "./index.module.scss";
import { styled } from "@mui/material/styles";
import ViewImage from "@/components/viewImage";
import VideoPlayer from "@/components/videoDom/index";
import UKToast from "@/common-components-src/components/UKToast/index";
import { imageUtil } from "@/common-components-src/js/utils/imageUtil";
import { uploadVideo, refundUploadVideo, uploadVideoFetch } from "@/common-components-src/api/upload/index.api";
import { imageUploadApi } from "@/common-components-src/api/image/index.api";
import ImgIconSvg from "@/common-components-src/assets/icons/img.svg";
import VideoIconSvg from "@/common-components-src/assets/icons/video.svg";
import DeleteIconSvg from "@/common-components-src/assets/icons/delete.svg";
import { PropsWithChildren, useState, useMemo, useEffect, useRef } from "react";

interface UploadMediaProps {
  videoBussinessType: string;
  videoParam?: any;
  type?: string;
  apiType: number;
  limitNumber?: number;
  limitVideoNumber?: number;
  containStyle?: React.CSSProperties;
  onChange?: (media: string[] | null) => void;
  onVideoChange?: (media: string[] | null) => void;
  onLoadingChange?: (isLoading: boolean) => void;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  top: 0,
  right: 0,
  whiteSpace: "nowrap",
  zIndex: 100,
});

const UploadBtn = ({
  accept,
  children,
  multiple = false,
  width = "100px",
  onChange,
}: PropsWithChildren & {
  accept: string;
  width?: string;
  multiple?: boolean;
  onChange?: (fileList: string[] | null) => void;
}) => {
  const onChangeUploadImg = async (fileList: FileList | null) => {
    if (!fileList?.length) {
      return null;
    }
    if (accept === "image/*") {
      onChange?.(fileList as any);
    } else if (accept === "video/*") {
      onChange?.(fileList as any);
    }
    return null;
  };
  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={children}
      sx={{
        width: { width },
        "&": {
          display: "block",
          background: "none",
          boxShadow: "none",
          padding: "0",
          "&:hover": {
            boxShadow: "none",
          },
        },
        "& .MuiButton-startIcon": {
          margin: 0,
        },
        "& .MuiButton-startIcon>*:nth-of-type(1)": {
          fontSize: "14px",
        },
      }}
    >
      <VisuallyHiddenInput type="file" onChange={(event) => onChangeUploadImg(event.target.files)} multiple={multiple} accept={accept} />
    </Button>
  );
};

export default function UKUpload({
  videoBussinessType = "refund", // 售后：refund；评价：reviews
  videoParam = { orderId: "0", skuId: 0 },
  type = "image",
  apiType = 1,
  limitNumber = 5,
  limitVideoNumber = 1,
  containStyle = {},
  onChange,
  onVideoChange,
  onLoadingChange,
}: UploadMediaProps) {
  const [imageDataList, setImageDataList] = useState<any>([]);
  const [showVideoUpload, setShowVideoUpload] = useState(true);
  const [showImageUpload, setShowImageUpload] = useState(true);
  const [openImage, setOpenImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const uploadImgCount = useRef(0);
  const maxSize = 5 * 1024 * 1024;
  const maxVideoSize = 15 * 1024 * 1024;

  const getImgCount = () => {
    let imageCount = 0;
    imageDataList.forEach((item: any) => {
      if (item && !item.isVideo) {
        imageCount += 1;
      }
    });
    return imageCount;
  };

  const getVideoCount = () => {
    let videoCount = 0;
    imageDataList.forEach((item: any) => {
      if (item && item.isVideo) {
        videoCount += 1;
      }
    });
    return videoCount;
  };

  const reviewBtnStyle = useMemo(() => {
    if (uploadImgCount.current > 0) {
      return false;
    }
    return imageDataList == null || imageDataList.length <= 0;
  }, [imageDataList]);

  const getWidth = () => {
    return reviewBtnStyle ? (type == "image_video" ? "49%" : "100%") : "100px";
  };

  const getImgUrlList = () => {
    const urlList: string[] = [];
    imageDataList.forEach((item: any) => {
      if (item && !item.isVideo && item.url) {
        urlList.push(item.url);
      }
    });
    return urlList;
  };

  const getVideoUrlList = () => {
    const urlList: string[] = [];
    imageDataList.forEach((item: any) => {
      if (item && item.isVideo) {
        urlList.push(item);
      }
    });
    return urlList;
  };

  // 图片上传
  const onUploadImageChange = async (files: any) => {
    if (files && files.length > 0) {
      let dataList = [...imageDataList];
      let uploadCount = limitNumber - getImgCount();
      let target = Math.min(files.length, uploadCount);
      if (files.length > uploadCount) {
        UKToast.show({
          icon: "error",
          content: `The maximum limit for uploading images is ${limitNumber}.`,
        });
        return;
      }
      if (target > 0) {
        setUploadCount(target);
        for (var i = 0; i < target; i++) {
          let file = files[i];
          let imgData = { upfile: file, uploading: true, delete: false };
          dataList.push(imgData);
          if (file.size > maxSize) {
            // 文件大于5M，进行压缩
            console.log("start img compress", file.size);
            imageUtil
              .compressImage(file, maxSize)
              .then((compressedFile: any) => {
                console.log("end compress", compressedFile.size);
                if (compressedFile.size > maxSize) {
                  showLimitError();
                  DeleteItemByDataList(imgData, dataList);
                  setUploadCount(uploadImgCount.current - 1);
                } else {
                  StartUploadImage(compressedFile, imgData, dataList);
                }
              })
              .catch((error) => {
                showLimitError();
                DeleteItemByDataList(imgData, dataList);
                setUploadCount(uploadImgCount.current - 1);
                console.error("img compress erroe: ", error);
              });
          } else {
            StartUploadImage(file, imgData, dataList);
          }
        }
        setImageDataList(dataList);
      }
    }
  };

  // 图片上传 上行协议
  const StartUploadImage = (file: any, data: any, dataList: any) => {
    imageUploadApi(file, apiType).then((res) => {
      setUploadCount(uploadImgCount.current - 1);
      if (res && res.id == "1") {
        data.url = res.msg;
        data.unload = false;
        data.uploading = false;
      } else {
        data.url = "";
        data.unload = true;
        data.uploading = false;
      }
      if (data.delete) {
        DeleteItemByDataList(data, dataList);
      } else {
        setImageDataList([...dataList]);
      }
    });
  };

  // 删除图片
  const OnDeleteImg = (item: any) => {
    if (item && item.uploading) {
      item.delete = true;
    } else {
      const newDataList = imageDataList.filter((element: any) => element != item);
      setImageDataList(newDataList);
    }
  };

  // 在列表中删除图片
  const DeleteItemByDataList = (item: any, dataList: any) => {
    const index = dataList.indexOf(item);
    dataList.splice(index, 1);
    setImageDataList([...dataList]);
  };

  // 重新上传图片
  const OnReload = async (item: any) => {
    if (item && item.upfile) {
      item.uploading = true;
      setImageDataList([...imageDataList]);
      if (item.isVideo) {
        const result = videoBussinessType === "refund" ? await refundUploadVideo(item.upfile, videoParam) : await uploadVideo(item.upfile, videoParam);
        if (result && result.success) {
          const res = await uploadVideoFetch(item.upfile, result.data?.uploadUrl);
          if (res && res.code === 0) {
            item.unload = false;
            item.videoUrl = res.videoUrl as string;
          }
        }
        item.uploading = false;
        setImageDataList([...imageDataList]);
      } else {
        imageUploadApi(item.upfile, apiType).then((res) => {
          item.uploading = false;
          if (res && res.id == "1") {
            item.url = res.msg;
            item.unload = false;
          }
          setImageDataList([...imageDataList]);
        });
      }
    }
  };

  // 上传视频
  const onUploadVideoChange = async (fileList: any) => {
    if (!fileList || fileList.length <= 0) return;
    let showToast = false;
    let realFileList = [];
    for (var i = 0; i < fileList.length; i++) {
      if (fileList[i].size > maxVideoSize) {
        showToast = true;
      } else {
        realFileList.push(fileList[i]);
      }
    }
    if (showToast) {
      UKToast.show({
        icon: "error",
        content: "The video size cannot exceed 15MB",
      });
    }
    const fileLength = realFileList.length;
    if (fileLength <= 0) return;
    let dataList = [...imageDataList];
    let uploadCount = limitVideoNumber - getVideoCount();
    let target = Math.min(fileLength, uploadCount);
    if (fileLength > uploadCount) {
      UKToast.show({
        icon: "error",
        content: `The maximum limit for uploading video is ${limitVideoNumber}.`,
      });
      return;
    }
    if (target <= 0) return;
    setUploadCount(target);
    for (var i = 0; i < target; i++) {
      let file = realFileList[i];
      let videoData = { isVideo: true, unload: false, uploading: true, delete: false, upfile: file, videoUrl: "", vid: "" };
      let duration = 0;
      const url = URL.createObjectURL(file);
      const fileElement = new Audio(url);
      fileElement.addEventListener("loadedmetadata", async function () {
        duration = fileElement.duration;
        if (duration > 15) {
          setUploadCount(uploadImgCount.current - 1);
          UKToast.show({
            icon: "error",
            content: "If your video exceeds 15 seconds, only the first 15 seconds will be uploaded.",
          });
        } else if (duration < 1) {
          setUploadCount(uploadImgCount.current - 1);
          UKToast.show({
            icon: "error",
            content: "The video duration cannot be less than 1 second",
          });
        } else {
          dataList.push(videoData);
          setImageDataList(dataList);
          const result = videoBussinessType === "refund" ? await refundUploadVideo(file, videoParam) : await uploadVideo(file, videoParam);

          if (result && result.success) {
            const res = await uploadVideoFetch(file, result.data?.uploadUrl);
            if (res && res.code === 0) {
              videoData.unload = false;
              videoData.uploading = false;
              videoData.videoUrl = res.videoUrl as string;
              videoData.vid = result.data?.vid as string;
            } else {
              videoData.unload = true;
              videoData.uploading = false;
            }
            onChangeVideoData(videoData, dataList);
            setUploadCount(uploadImgCount.current - 1);
          } else {
            videoData.unload = true;
            videoData.uploading = false;
            onChangeVideoData(videoData, dataList);
            setUploadCount(uploadImgCount.current - 1);
          }
        }
      });
    }
  };

  const onChangeVideoData = (videoData: any, dataList: any) => {
    if (videoData.delete) {
      DeleteItemByDataList(videoData, dataList);
    } else {
      setImageDataList([...dataList]);
    }
  };

  useEffect(() => {
    handleImageUpload();
    onChange?.(getImgUrlList());
    onVideoChange?.(getVideoUrlList());
    console.log("imageDataList", imageDataList);
  }, [imageDataList, limitNumber, limitVideoNumber]);

  const setUploadCount = (count: number) => {
    uploadImgCount.current = count;
    onLoadingChange?.(uploadImgCount.current > 0);
  };

  const showLimitError = () => {
    UKToast.show({
      icon: "error",
      content: <span>The image size cannot exceed 5MB</span>,
    });
  };

  const handleImageUpload = () => {
    const imageCount = getImgCount();
    const videoCount = getVideoCount();
    const showImageUpload = imageCount < limitNumber;
    const showVideoUpload = videoCount < limitVideoNumber;
    setShowImageUpload(showImageUpload);
    setShowVideoUpload(showVideoUpload);
  };

  return (
    <div className={styles.mediaContainer} style={containStyle}>
      {!!imageDataList && imageDataList.length > 0 && (
        <>
          {imageDataList.map((item: any, index: number) => (
            <div className={styles.uploadImgWrapper} key={index}>
              {/* 图片 */}
              {item && !item.isVideo && !item.uploading && !item.unload && (
                <img src={imageUtil.concatImgUrlWithoutForceHttps(item.url)} alt="" loading="lazy" className={styles.imgIcon} />
              )}
              {/* 视频 */}
              {item && item.isVideo && !item.uploading && !item.unload && <VideoPlayer src={item.videoUrl} />}
              {/* 查看图片按钮 */}
              {item && !item.isVideo && !item.uploading && !item.unload && (
                <div className={styles.imgWrapper}>
                  <span
                    className={styles.viewIcon}
                    onClick={() => {
                      setImageUrl(item.url);
                      setOpenImage(true);
                    }}
                  >
                    View
                  </span>
                </div>
              )}
              {/* 加载中 */}
              {item && item.uploading && (
                <div className={styles.imageLoading}>
                  <img src="https://st.joy-sourcing.com/website/home/loading.png" alt="" />
                </div>
              )}
              {/* 重新上传按钮 */}
              {item && !item.uploading && item.unload && (
                <button onClick={() => OnReload(item)} className={styles.imgReUpload}>
                  Re-upload
                </button>
              )}
              {/* 删除图片按钮 */}
              <DeleteIconSvg className={styles.delete} onClick={() => OnDeleteImg(item)} />
            </div>
          ))}
          <ViewImage open={openImage} imageUrl={imageUrl} urlList={getImgUrlList()} closeView={() => setOpenImage(false)} />
        </>
      )}
      {showImageUpload && (
        <UploadBtn width={getWidth()} accept="image/*" multiple onChange={(fileList) => onUploadImageChange(fileList)}>
          <div className={styles.upload}>
            <ImgIconSvg className={styles.image} />
            <span>Upload image</span>
          </div>
        </UploadBtn>
      )}
      {type == "image_video" && showVideoUpload && limitVideoNumber > 0 && (
        <UploadBtn width={getWidth()} accept="video/*" multiple onChange={(fileList) => onUploadVideoChange(fileList)}>
          <div className={styles.upload}>
            <VideoIconSvg className={styles.video} />
            <span>Upload video</span>
          </div>
        </UploadBtn>
      )}
    </div>
  );
}

/* eslint-disable react/no-unknown-property */
/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from "clsx";
import React from "react";
import style from "./style.module.scss";
import ImageDom from "./components/imageDom";
import LoadingIcon from "@/common-components-src/components/loadingIcon/index";
import IntlDialog from "@/common-components-src/js/reviews/dialog";

import {
  Radio,
  Popover,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import {
  getReportReason,
  reportComment,
  handleDeleteComment,
  fetchUserReviewDetail,
} from "@/common-components-src/api/reviews/review.api";
import PageLeft  from "@/common-components-src/assets/icons/pageLeft.svg";
import PageRight  from "@/common-components-src/assets/icons/pageRight.svg";
import CloseImage  from "@/common-components-src/assets/icons/pageClose.svg";
import PauseIcon  from "@/common-components-src/components/videoDom/icons/pauseIcon.svg";
import VolumeIcon  from "@/common-components-src/components/videoDom/icons/volumeIcon.svg";
import StartPlaying  from "@/common-components-src/components/videoDom/icons/start_playing.svg";
import VideoPayBig  from "@/common-components-src/components/videoDom/icons/start_playing_big.svg";
import FullScreenIcon  from "@/common-components-src/components/videoDom/icons/fullScreenIcon.svg";
import { CancelButton, SubmitButton } from "./components/button";
import { useRef, useState, useEffect, useImperativeHandle, forwardRef } from "react";
import UKToast from "../UKToast/index.tsx";
import { imageUtil } from "@/common-components-src/js/utils/imageUtil";

interface PropsType {
  open: boolean;
  imageUrl: string;
  nickName?: string;
  content?: string;
  isContent?: boolean;
  reportCommentParams?: any;
  children?: React.ReactNode;
  urlList: { type: any; url: string }[];
  closeView: () => void;
  reviewFooterCountyFn?: (type: string, params: any) => void;
}
// 举报弹框
const FormContent = forwardRef<HTMLDivElement>((props, ref): any => {
  const [formFieldValue, setFormFieldValue] = useState({
    reportReason: { value: "1" },
    reportContent: { value: "" },
  });
  const [reasonList, setReasonList] = useState<any[]>([]);

  const onTextareaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length >= 200) {
      return;
    }
    setFormFieldValue({
      ...formFieldValue,
      reportContent: { value: (event.target as HTMLInputElement).value },
    });
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormFieldValue({
      ...formFieldValue,
      reportReason: { value: (event.target as HTMLInputElement).value },
    });
  };

  const getReportReasonList = async () => {
    const { reasonList: resReasonList = [] } = (await getReportReason()) || {};
    setReasonList(resReasonList);
  };
  useImperativeHandle(ref, () => {
    return {
      getFormFieldValue: () => {
        return formFieldValue;
      },
    } as any;
  }, [formFieldValue]);

  useEffect(() => {
    getReportReasonList();
  }, []);

  return (
    <div className={style.reportFormWrapper}>
      <FormControl>
        <label id="demo-radio-buttons-group-label" className={style.reasonLabel}>
          Reason
        </label>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={formFieldValue["reportReason"].value}
          name="reportReason"
          onChange={handleRadioChange}
          sx={{
            "& .MuiRadio-root": {
              color: "rgba(224, 225, 229, 1)",
            },
          }}
        >
          {reasonList?.map((reason, index) => {
            return (
              <FormControlLabel
                key={index}
                value={reason.id}
                control={<Radio />}
                label={reason.title}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      <div className={style.commentTextarea}>
        <p className={style.title}>Detailed description</p>
        <textarea
          value={formFieldValue["reportContent"].value}
          onChange={(e: any) => onTextareaChange(e)}
          placeholder={"Please enter the detailed reason."}
        ></textarea>
        <span className={style.num}>{formFieldValue.reportContent.value.length + 1}/200</span>
      </div>
    </div>
  );
});
FormContent.displayName = "FormContent";
const PreviewImageVideo: React.FC<PropsType> = ({
  open,
  urlList,
  content,
  imageUrl,
  children,
  nickName,
  closeView,
  isContent = false,
  reportCommentParams,
  reviewFooterCountyFn,
}) => {
  const [openImage, setOpen] = React.useState(false); // 是否打开预览
  const [isLoading, setIsLoading] = React.useState(false); // 是否加载中
  const [currentUrl, setCurrentUrl] = React.useState(""); // 当前图片
  const [currentType, setCurrentType] = React.useState(""); // 当前图片类型
  const [currentIndex, setCurrentIndex] = React.useState(0); // 当前图片下标

  React.useEffect(() => {
    setOpen(open);
    console.log("yz log - urlList", urlList);
    urlList?.length > 0 &&
      urlList.forEach((item: any, index: number) => {
        if (item.url == imageUrl) {
          setCurrentUrl(item.url);
          setCurrentIndex(index);
          setCurrentType(item.type);
          // getVideoTime();
        }
      });
  }, [open, imageUrl]);
  // 翻页
  const handlePageTurning = (type: "left" | "right") => {
    if (videoRef.current) {
      setVideoMaskPay(false);
      videoRef?.current?.pause();
    }
    if (urlList && urlList?.length > 0) {
      if (type === "left") {
        if (currentIndex <= 0) {
          return;
        }
        urlList.forEach((item: any, index) => {
          if (!item.url) {
            return;
          }
          if (item.url == currentUrl) {
            // 当前图片在 下标 为 1 的位置
            setCurrentUrl(urlList[index - 1]?.url);
            setCurrentType(urlList[index - 1]?.type);
            setCurrentIndex(index - 1);
          }
        });
      } else {
        if (currentIndex >= urlList.length - 1) {
          return;
        }
        urlList.forEach((item: any, index) => {
          if (item.url == currentUrl) {
            setCurrentUrl(urlList[index + 1]?.url);
            setCurrentType(urlList[index + 1]?.type);
            setCurrentIndex(index + 1);
          }
        });
      }
    } else {
      return;
    }
  };
  const videoRef = useRef<any>(null); // 视频 ref
  const volumeRef = useRef<HTMLDivElement>(null); // 音量 ref
  const progressRef = useRef<HTMLDivElement>(null); // 进度条 ref

  const [volumeDom, setVolume] = useState(0); // 音量
  const [duration, setDuration] = useState<any>(0); // 视频时长
  const [currentTime, setCurrentTime] = useState<any>(0); // 当前时间
  const [isFullscreen, setIsFullscreen] = useState(false); // 是否全屏
  const [videoMaskPay, setVideoMaskPay] = useState(false); // 是否打开预览模式 大图
  const [isVolumeSlider, setIsVolumeSlider] = useState(false); // 是否显示音量条

  // 获取视频时长
  const getVideoTime = () => {
    const video = videoRef.current;
    if (!video) {
      return;
    }
    const timeUpdate = () => {
      setCurrentTime(video.currentTime);
    };
    const loadedMetadata = () => {
      setDuration(video.duration);
    };
    const ended = () => setVideoMaskPay(false);
    video.addEventListener("timeupdate", timeUpdate);
    video.addEventListener("loadedmetadata", loadedMetadata);
    video.addEventListener("ended", ended);
    return () => {
      video.removeEventListener("timeupdate", timeUpdate);
      video.removeEventListener("loadedmetadata", loadedMetadata);
      video.removeEventListener("ended", ended);
    };
  };

  // 点击 中间播放按钮 开始播放视频
  const videoPayBigIconFn = () => {
    setIsVolumeSlider(false);
    // 检查是否需要显示付费大图标
    if (!videoMaskPay) {
      // 显示付费大图标并播放视频
      setVideoMaskPay(true);
      // 如果视频元素存在，则开始播放视频
      if (videoRef.current) {
        getVideoTime();
        videoRef.current.play();
      }
    } else {
      // 隐藏付费大图标并暂停视频播放
      setVideoMaskPay(false);
      // 如果视频元素存在，则暂停视频播放
      if (videoRef.current) {
        videoRef?.current?.pause();
      }
    }
  };

  // 视频拖动控制
  const handleProgressDrag = (e: React.MouseEvent<HTMLDivElement>) => {
    if (videoRef.current && progressRef.current) {
      const rect = progressRef.current.getBoundingClientRect();
      const pos = (e.clientX - rect.left) / rect.width;
      if (videoRef.current.currentTime) {
        videoRef.current.currentTime = pos * duration;
        videoRef.current.play();
        setVideoMaskPay(true);
      }
    }
  };

  // 音量控制
  const handleVolumeChange = (e: React.MouseEvent<HTMLDivElement>) => {
    if (volumeRef.current) {
      const rect = volumeRef.current.getBoundingClientRect();
      const newVolume = 1 - (e.clientY - rect.top) / rect.height;
      const clampedVolume = Math.max(0, Math.min(1, newVolume));
      setVolume(clampedVolume);
      if (videoRef.current) {
        videoRef.current.volume = clampedVolume;
      }
    }
  };

  // 格式化时间
  const formatTime = (time: any) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  // 预览模式打开全屏
  const openFullScreen = () => {
    setIsVolumeSlider(false);
    if (videoRef.current) {
      if (isFullscreen) {
        // 关闭视频
        if (document && document.fullscreenElement) {
          // 关闭全屏
          document.exitFullscreen();
          setVideoMaskPay(true);
          videoRef.current.pause();
        }
      } else {
        setVideoMaskPay(false);
        // 全屏
        videoRef.current.requestFullscreen();
        // 播放视频
        videoRef.current.play();
      }
      setIsFullscreen(!isFullscreen);
    }
  };

  // 监听 video 的时长
  useEffect(() => {
    getVideoTime();
  }, [currentUrl]);

  // 监听 esc 键
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Escape" || event.code === "Escape") {
        setVideoMaskPay(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //* ----------------------------------------------------------- 举报弹框, 删除 ----------------------------------------------------------- *//
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false); // 删除弹窗
  const formRef = useRef<any>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? "simple-popover" : "";
  // 举报弹框打开
  const onReportAndBlock = () => {
    setOpenDialog(true);
    handlePopoverClose();
  };
  // 举报弹框关闭
  const reportAndBlockClose = () => {
    setOpenDialog(false);
  };
  // 提交举报
  const onFormSubmit = async () => {
    // 如果评论内容为空，且没有图片和视频，则不支持举报
    if (!content && urlList?.length == 0) {
      UKToast.show({
        content: "Sorry, the review content is empty and reporting is not supported.",
        icon: "warning",
      });
      return;
    }
    const r: any = await fetchUserReviewDetail({
      skuId: reportCommentParams?.skuId,
      parentReplyId: reportCommentParams?.parentReplyId || 0,
      commentId: reportCommentParams?.commentId,
    });
    if (!r?.success) {
      UKToast.show({
        content: r?.msg,
        icon: "warning",
      });
      return;
    }
    const formData = formRef.current.getFormFieldValue();
    const formParams = Object.keys(formData).reduce((pre, cur) => {
      return { ...pre, [cur]: formData[cur].value };
    }, {});
    const data = {
      commentId: r?.data?.commentId,
      targetReplyId: r?.data?.targetReplyId,
      // parentReplyId: r?.data?.parentReplyId,
      toPin: r?.data?.userPin,
      toContent: r?.data?.content,
    };
    // delete reportReqParams.parentReplyId;
    const res = await reportComment({
      ...formParams,
      ...(data || {}),
    });
    if (res?.saveResult) {
      setOpenDialog(false);
      UKToast.show({
        sx: { zIndex: 10002 },
        content: (
          <>
            <p>Report successfully.</p>
            <p>Thank you! your feedack helps us improve Joybuy.</p>
          </>
        ), // 弹窗内容
        icon: "success",
      });
      reportAndBlockClose();
      // reportFn && reportFn();
    } else if (res.code !== "") {
      UKToast.show({
        sx: { zIndex: 10002 },
        content: res.msg || (
          <>
            <p>Oops! Something went wrong.</p>
            <p>We&apos;re working on resolving the issue. Please check back later.</p>
          </>
        ),
        icon: "warning",
      });
    } else {
      UKToast.show({
        sx: { zIndex: 10002 },
        content: res?.msg ||  "Report error.",
        icon: "error",
      });
    }
  };

  // 删除评价
  const onDeleteReview = async () => {
    setIsLoading(true);
    console.log(reportCommentParams, "reportCommentParams");
    try {
      const res: any = await handleDeleteComment({
        skuId: reportCommentParams?.skuId as string,
        commentId: reportCommentParams?.commentId as string,
      });
      if (res && res.success) {
        UKToast.show({
          icon: "success",
          content: "Delete successfully",
        });
        reviewFooterCountyFn?.("previewDelete", {
          skuId: reportCommentParams?.skuId,
          commentId: reportCommentParams?.commentId,
        });
        setDeleteOpen(false);
        setIsLoading(false);
        closeView?.();
      } else {
        UKToast.show({
          icon: "error",
          content: res.msg || `Failed to delete, please try again.`,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  if (!openImage) {
    return;
  }
  return (
    <>
      {openImage && (
        <div className={style.view_image}>
          <div className={style.mack}>
            <div className={style.imgVideoBox}>
              {currentType == "0" && (
                <VideoPayBig
                  className={cn(style.videoPayBig, videoMaskPay ? style.videoPayBigShow : "")}
                  onClick={videoPayBigIconFn}
                />
              )}
              {currentType == "0" ? (
                <div className={style.videoPlayer_mask_wrap}>
                  <div className={style.videoPlayer_mask_wrap_video}>
                    <video
                      onClick={videoPayBigIconFn}
                      ref={videoRef}
                      src={currentUrl}
                      style={{
                        // maxWidth: "100%",
                        height: "calc(100% - 40px)",
                        // objectFit: "cover",
                        cursor: "pointer",
                      }}
                    ></video>
                  </div>

                  {/* 设置按钮盒子 */}
                  <div className={style.consoleWrap}>
                    {/* 右边按钮盒子 */}
                    <div className={style.consoleWrap_right}>
                      {videoMaskPay ? (
                        <div className={style.consoleIcon}>
                          <PauseIcon onClick={videoPayBigIconFn} />
                        </div>
                      ) : (
                        <div className={style.consoleIcon}>
                          <StartPlaying onClick={videoPayBigIconFn} />
                        </div>
                      )}
                    </div>

                    {/* 中间盒子 */}
                    <div className={style.consoleWrapCenter}>
                      {/* 时长 */}
                      <div className={style.duration}>{formatTime(currentTime)}</div>
                      {/* 进度条 */}
                      <div className={style.progressBarWrap}>
                        <div
                          ref={progressRef}
                          className={style.progressBar}
                          onClick={handleProgressDrag}
                        >
                          <div
                            className={style.progressFilled}
                            style={{ width: `${(currentTime / duration) * 100}%` }}
                          />
                        </div>
                        {/* 当前时间 */}
                        <div className={style.currentTime}>{formatTime(duration)}</div>
                      </div>
                    </div>

                    {/* 左侧按钮盒子 */}
                    <div className={style.consoleWrap_left}>
                      {/* 音量 */}
                      <div className={cn(style.consoleIcon, style.volumeIcon)}>
                        {isVolumeSlider && (
                          <div
                            ref={volumeRef}
                            className={style.volumeSlider}
                            onClick={handleVolumeChange}
                          >
                            <div
                              className={style.volumeLevel}
                              style={{ height: `${volumeDom * 85}%` }}
                            />
                          </div>
                        )}
                        <VolumeIcon onClick={() => setIsVolumeSlider(!isVolumeSlider)} />
                      </div>
                      {/* 放大 */}
                      <div className={style.consoleIcon}>
                        <FullScreenIcon onClick={openFullScreen} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <ImageDom
                  src={imageUtil.concatImgUrlWithoutForceHttps(
                    currentUrl,
                    1400,
                    0,
                    100,
                    process.env.NEXT_PUBLIC_IMAGE_CDN_DOMAIN?.split(","),
                  )}
                  alt=""
                  imgStyle={{ height: "100%", width: "100%", objectFit: "contain" }}
                />
              )}
            </div>

            {/* 显示内容 */}
            {isContent && (
              <>
                <div className={style.header}>
                  <div className={style.nickName}>{nickName}</div>
                  <div className={style.currentIndex}>
                    <span>
                      {currentIndex + 1}/{urlList.length}
                    </span>
                    <i
                      style={{ padding: "0 10px", cursor: "pointer" }}
                      aria-describedby={id}
                      onClick={(e: any) => handleClick(e)}
                    >
                      ···
                    </i>
                    <Popover
                      id={id}
                      open={popoverOpen}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      sx={{
                        zIndex: 9999,
                        "& .MuiPopover-paper": {
                          boxShadow: "0px 4px 28px 0px rgba(0, 0, 0, 0.08)",
                        },
                      }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                    >
                      {reportCommentParams?.currentUserOperation && (
                        <Typography
                          sx={{ p: 1 }}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setDeleteOpen(true);
                            handlePopoverClose();
                          }}
                        >
                          Delete
                        </Typography>
                      )}
                      {!reportCommentParams?.currentUserOperation && (
                        <Typography
                          sx={{ p: 1 }}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            onReportAndBlock();
                            handlePopoverClose();
                          }}
                        >
                          Report
                        </Typography>
                      )}
                    </Popover>
                  </div>
                </div>
                <div className={style.content}>{content}</div>
              </>
            )}
            <div className={style.children}>{children}</div>
          </div>
          <div className={style.pageLeft} onClick={() => handlePageTurning("left")}>
            <PageLeft />
          </div>
          <div className={style.pageRight} onClick={() => handlePageTurning("right")}>
            <PageRight />
          </div>
          <CloseImage className={style.closeIcon} onClick={() => closeView && closeView()} />
        </div>
      )}
      {/* 举报弹框 */}
      <IntlDialog
        onClose={reportAndBlockClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        title="Reason for Reporting"
        sx={{ zIndex: 10002 }}
        content={
          <>
            <div className={style.reportReasonWrapper}>
              Thanks for leaving a feedback! This will help us to build a better shopping
              environment for all.
            </div>
            <FormContent ref={formRef} />
            <div className={style.dialogFooterAction}>
              <CancelButton onClick={reportAndBlockClose}>Cancel</CancelButton>
              <SubmitButton onClick={onFormSubmit}>Submit</SubmitButton>
            </div>
          </>
        }
        width={520}
      />
      {/* 删除弹窗 */}
      <IntlDialog
        sx={{ zIndex: 12002 }}
        onClose={() => setDeleteOpen(false)}
        title=""
        open={deleteOpen}
        content={
          <>
            <div className={style.deleteText}>
              Do you confirm to delete your review? This action is irreversible.
            </div>
            <div className={style.dialogFooterAction}>
              <CancelButton onClick={() => setDeleteOpen(false)}>Cancel</CancelButton>
              <SubmitButton
                disabled={isLoading}
                startIcon={isLoading ? <LoadingIcon /> : null}
                onClick={onDeleteReview}
              >
                Submit
              </SubmitButton>
            </div>
          </>
        }
        width={520}
      />
    </>
  );
};

export default PreviewImageVideo;

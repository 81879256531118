import { useState } from "react";
import style from "@/common-components-src/js/floatingToolbar/style.module.scss";
import MessageBtn from "@/common-components-src/js/floatingToolbar/components/MessageBtn";
import BackTopBtn from "@/common-components-src/js/floatingToolbar/components/BackTopBtn";
import FeedbackBtn from "@/common-components-src/js/floatingToolbar/components/FeedbackBtn";
import CustomerSupport from "@/common-components-src/js/floatingToolbar/components/CustomerSupport";

export interface Props {
  open?: boolean;
  onOpen: () => void;
}

const JDIFloatingToolbar = () => {

  const [isOpenMessage, setIsOpenMessage] = useState<boolean>(false);

  const handleOpenMessage = () => {
    closeCustomerService();
    setIsOpenMessage(true);
  }

  const handleOpenService = () => {
    setIsOpenMessage(false);
  }

  const handleOpenFeedback = () => {
    setIsOpenMessage(false);
    closeCustomerService();
  }

  const closeCustomerService = () => {
    if (window && (window as any).zE) {
      (window as any)?.zE("messenger", "close");
    }
  }

  return (
    <div id="jdi-floating-toolbar" className={style.floatingToolbar}>
      <MessageBtn open={isOpenMessage} onOpen={handleOpenMessage} />
      <CustomerSupport onOpen={handleOpenService} />
      <FeedbackBtn onOpen={handleOpenFeedback} />
      <BackTopBtn />
    </div>
  );
};

export default JDIFloatingToolbar;

import colorAxiosInstance from "../new-color-axiosInstance";

interface Response<T> {
  code: string | number;
  success: boolean;
  videoUrl: string;
  data: T;
}

// 评价上传视频
export const uploadVideo = async (file: any, data: any): Promise<Response<any>> => {
  return colorAxiosInstance
    .post<any>("content_review_getVideoUrl", undefined, {
      params: {
        fileSize: file?.size,
        ...data,
      },
    })
    .then((response: any) => response!.data)
    .catch((error: any) => {
      console.log(error);
    });
};

// 售后上传视频
export const refundUploadVideo = async (file: any, data: any): Promise<Response<any>> => {
  return colorAxiosInstance
    .post<any>("aftersale_refund_getVideoUploadUrl", undefined, {
      params: {
        fileSize: file?.size,
        ...data,
      },
    })
    .then((response: any) => response!.data)
    .catch((error: any) => {
      console.log(error);
    });
};

export const uploadVideoFetch = async (file: any, uploadUrl: any): Promise<Response<any>> => {
  const formData = new FormData();
  formData.append("file", file);
  const url = URL.createObjectURL(file);
  const response = await fetch(uploadUrl, {
    method: "POST",
    body: formData,
  });
  if (!response.ok) {
    throw new Error("Failed to upload video");
  }
  const result = await response.json();
  return { ...result, videoUrl: url };
};
